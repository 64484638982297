/*
* IcoMoon
*/
@import "bootstrap.min.css";
@font-face {
  font-family: 'icomoon';
  src: url('/web/fonts/icomoon.eot?7v7fny');
  src: url('/web/fonts/icomoon.eot?7v7fny#iefix') format('embedded-opentype'), url('/web/fonts/icomoon.ttf?7v7fny') format('truetype'), url('/web/fonts/icomoon.woff?7v7fny') format('woff'), url('/web/fonts/icomoon.svg?7v7fny#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-sphere:before {
  content: "\e900";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-twitter2:before {
  content: "\e902";
}
.icon-github:before {
  content: "\e903";
}
.icon-linkedin:before {
  content: "\e904";
}
.icon-linkedin2:before {
  content: "\e905";
}
.icon-stackoverflow:before {
  content: "\e906";
}
.icon-menu:before {
  content: "\e907";
}
.icon-cross:before {
  content: "\e908";
}
.icon-facebook:before {
  content: "\e909";
}
.icon-facebook2:before {
  content: "\e90a";
}
.icon-mail:before {
  content: "\e90b";
}
.icon-open_in_new:before {
  content: "\e90c";
}
@media screen and (max-width: 500px) {
  .m-hide {
    display: none !important;
  }
}
/*
* Also a comment, block level
* The magic starts here
*/
html,
body {
  font-family: "soleil", Arial, sans-serif;
  text-align: center;
  font-size: 18px;
  background-color: #f9f9f9;
  transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -webkit-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
}
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 160px;
}
/*
.headline.rotate-1 .words-wrapper {
  display: inline-block;
  perspective: 1000px;
}
.headline a {
	display: inline-block;
}
.headline.rotate-1 b {
  opacity: 0;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
.headline.rotate-1 b.is-visible {
  position: relative;
  opacity: 1;
  transform: rotateX(0deg);
  animation: rotate-1-in 0.75s;
}
 
.headline.rotate-1 b.is-hidden {
  transform: rotateX(180deg);
  animation: rotate-1-out 0.75s;
}
@keyframes rotate-1-in {
  0% {
    transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(360deg);
    opacity: 1;
  }
}
 
@keyframes rotate-1-out {
  0% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  35% {
    transform: rotateX(-40deg);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(180deg);
    opacity: 0;
  }
}
*/
header {
  background-color: rgba(129, 255, 156, 0.5);
  transition: background-color 0.4s ease-out;
  -moz-transition: background-color 0.4s ease-out;
  -webkit-transition: background-color 0.4s ease-out;
  -o-transition: background-color 0.4s ease-out;
  text-align: center;
}
header #head {
  margin: 0 auto;
  max-width: 900px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
header #head h1 {
  font-family: "soleil", arial, sans-serif;
  font-size: 64px;
  font-weight: 700;
  margin: 0;
  padding: 54px 100px;
}
@media screen and (max-width: 500px) {
  header #head h1 {
    font-size: 32px;
    padding: 20px 20px 10px 20px;
  }
}
header #head ul#navigation {
  text-align: center;
  list-style: none;
  overflow: auto;
  margin: 0;
  padding: 0;
}
header #head ul#navigation li {
  display: inline-block;
  margin-right: 0px;
  padding-bottom: 15px;
  padding-top: 15px;
}
header #head ul#navigation a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  margin-right: 20px;
}
header #head ul#navigation a:hover,
header #head ul#navigation a.active {
  color: #000000;
}
header #head ul#navigation li:last-child a {
  margin-right: 0;
}
header #head.project h1 {
  padding-bottom: 154px;
}
@media screen and (max-width: 500px) {
  header #head.project h1 {
    padding: 40px 20px 140px 20px;
  }
}
/*
* Header color definitions
*/
#home header {
  background-color: rgba(129, 255, 156, 0.5);
}
#skills header,
#about header {
  background-color: rgba(129, 255, 156, 0.5);
}
#work header {
  background-color: rgba(129, 255, 156, 0.5);
}
#contact header {
  background-color: rgba(129, 255, 156, 0.5);
}
section .content {
  margin: 0 auto;
  padding: 54px 10px 0px 10px;
  max-width: 580px;
}
@media screen and (max-width: 500px) {
  section .content {
    padding: 40px 20px 0px 20px;
  }
}
section .content .small {
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 23px;
}
section .content p.centered {
  text-align: center;
}
section .content p.c2a {
  font-weight: 700;
  margin-top: 54px;
  margin-bottom: 14px;
}
section .content h2:first-child {
  margin-top: 0px;
}
section .work {
  margin: 0 auto;
  max-width: 900px;
  text-align: center;
  margin-top: 54px;
}
section .work ul {
  text-align: center;
  list-style: none;
  overflow: auto;
  margin: 0;
  padding: 0;
}
section .work ul li {
  display: inline-block;
  width: 25%;
  margin-right: 0px;
  padding-bottom: 27px;
}
@media screen and (max-width: 500px) {
  section .work ul li {
    width: 40%;
  }
}
section .work ul li p.desc {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
section .work ul li a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}
section .work ul li a:hover {
  color: #000000;
}
section .work ul li img {
  zoom: 50%;
  -moz-transform: scale(0.5);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.4;
  transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}
section .work ul li.overview img {
  zoom: 20%;
  -moz-transform: scale(0.2);
  margin: 0 20px 20px 20px;
}
section .work ul li:hover img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}
section .screenshots {
  margin: 0 auto;
  max-width: 900px;
  text-align: left;
  margin-top: -100px;
  padding: 0px 20px 0px 20px;
  position: relative;
  padding-bottom: 13px;
}
@media screen and (max-width: 500px) {
  section .screenshots {
    padding-bottom: 0;
  }
}
section .screenshots img {
  border: 2px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}
section .screenshots.full-mobile img.full {
  margin-left: 50px;
}
@media screen and (max-width: 500px) {
  section .screenshots.full-mobile img.full {
    margin: 0 0;
  }
}
section .screenshots.full-mobile img.mobile {
  max-width: 200px;
  position: absolute;
  top: 70px;
  right: 70px;
}
section .screenshots.full-screen img.screen {
  max-width: 350px;
  position: absolute;
  top: 60px;
  right: 20px;
}
section#projects ul li {
  margin-bottom: 13px;
}
footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: transparent;
  text-align: center;
}
footer #foot {
  margin: 0 auto;
  max-width: 580px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
footer #foot ul.networks {
  text-align: center;
  list-style: none;
  overflow: auto;
  margin: 0;
  padding: 0;
}
footer #foot ul.networks li {
  display: inline-block;
  margin-right: 0px;
  padding: 15px 0px;
}
footer #foot ul.networks li:last-child a {
  margin-right: 0;
}
footer #foot ul.networks a {
  color: #888;
  text-decoration: none;
  margin-right: 20px;
}
footer #foot ul.networks a:hover {
  color: #000;
}
footer #foot .network-name {
  display: none;
}
@media screen and (max-width: 500px) {
  footer #foot .network-name {
    display: none;
  }
}
/*
* Default typography
*/
p,
ul {
  line-height: 27px;
  text-align: left;
  margin-bottom: 27px;
}
@media screen and (max-width: 500px) {
  p,
  ul {
    font-size: 16px;
    line-height: 23px;
  }
}
ul {
  padding-left: 23px;
}
p.larger {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 32px;
}
@media screen and (max-width: 500px) {
  p.larger {
    font-size: 18px;
    line-height: 27px;
  }
}
#intro p {
  margin-bottom: 16px;
}
.content:hover p,
.content:hover ul {
  color: #000;
}
.wf-loading h1,
.wf-loading h2,
.wf-loading h3,
.wf-loading p,
.wf-loading ul {
  visibility: hidden;
}
.wf-active h1,
.wf-active h2,
.wf-active h3,
.wf-active p,
.wf-active ul,
.wf-inactive h1,
.wf-inactive h2,
.wf-inactive h3,
.wf-inactive p,
.wf-inactive ul {
  visibility: visible;
}
h2 {
  display: inline-block;
  font-size: 22px;
  line-height: 32px;
  border: 5px solid #000;
  padding: 1px 10px 3px 10px;
  margin: 27px 0px 27px 0px;
  background-color: #000;
  color: #fff;
}
@media screen and (max-width: 500px) {
  h2 {
    font-size: 18px;
    line-height: 27px;
    margin: 23px 0px 23px 0px;
  }
}
h3 {
  display: inline-block;
  font-size: 16px;
  line-height: 25px;
  border: 5px solid #000;
  padding: 1px 10px 3px 10px;
  margin: 25px 0px 25px 0px;
  background-color: #000;
  color: #fff;
}
/*
* Links
*/
a,
a:link,
a:visited {
  color: #333;
  text-decoration: underline;
  transition: color 0.2s ease-out;
  -moz-transition: color 0.2s ease-out;
  -webkit-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
}
a:hover,
a:active {
  color: #000;
}
h1 a,
h1 a:link,
h1 a:active,
h1 a:visited {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}
h1 a:hover {
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
}
a.button {
  font-size: 22px;
  line-height: 100%;
  display: inline-block;
  border: 2px solid rgba(0, 0, 0, 0.5);
  padding: 7px 15px 10px 15px;
  margin-bottom: 27px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
a.button:hover,
.content:hover a.button {
  border-color: #000;
  background-color: #fff;
  color: #000;
}
