@fonts: '/web/fonts';
/*
* IcoMoon
*/

@font-face {
	font-family: 'icomoon';
	src:url('@{fonts}/icomoon.eot?7v7fny');
	src:url('@{fonts}/icomoon.eot?7v7fny#iefix') format('embedded-opentype'),
		url('@{fonts}/icomoon.ttf?7v7fny') format('truetype'),
		url('@{fonts}/icomoon.woff?7v7fny') format('woff'),
		url('@{fonts}/icomoon.svg?7v7fny#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-sphere:before {
    content: "\e900";
}
.icon-twitter:before {
    content: "\e901";
}
.icon-twitter2:before {
    content: "\e902";
}
.icon-github:before {
    content: "\e903";
}
.icon-linkedin:before {
    content: "\e904";
}
.icon-linkedin2:before {
    content: "\e905";
}
.icon-stackoverflow:before {
    content: "\e906";
}
.icon-menu:before {
    content: "\e907";
}
.icon-cross:before {
    content: "\e908";
}
.icon-facebook:before {
    content: "\e909";
}
.icon-facebook2:before {
    content: "\e90a";
}
.icon-mail:before {
    content: "\e90b";
}
.icon-open_in_new:before {
    content: "\e90c";
}