//Main color definitions
@color-yellow: #ffe581;
@color-blue: #81daff;
@color-green: #81ff9c;
@color-red: #ff6035;

//Images path
@images: "../gfx";

//Includes
@import (less) "fonts.less";
@import (css) "bootstrap.min.css";

.border-radius (@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}

.breakpoint-small(@rules) {
  @media screen and (max-width: 500px) { @rules(); }
}

.m-hide {
	.breakpoint-small({
		display: none !important;
	});
}

/*
* Also a comment, block level
* The magic starts here
*/

//Comment

html, body {
	font-family: "soleil",Arial,sans-serif;
	text-align: center;
	font-size: 18px;
	background-color: #f9f9f9;
    transition: background-color .2s ease-out;
    -moz-transition: background-color .2s ease-out;
    -webkit-transition: background-color .2s ease-out;
    -o-transition: background-color .2s ease-out;	
}

//Serves CSS sticky footer
html {
    position: relative;
    min-height: 100%;	
}
//Serves CSS sticky footer: height footer + extra margin on top of footer
body {
	margin-bottom: 160px;
}


//Rotating text in header

/*
.headline.rotate-1 .words-wrapper {
  display: inline-block;
  perspective: 1000px;
}
.headline a {
	display: inline-block;
}
.headline.rotate-1 b {
  opacity: 0;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
.headline.rotate-1 b.is-visible {
  position: relative;
  opacity: 1;
  transform: rotateX(0deg);
  animation: rotate-1-in 0.75s;
}
 
.headline.rotate-1 b.is-hidden {
  transform: rotateX(180deg);
  animation: rotate-1-out 0.75s;
}
@keyframes rotate-1-in {
  0% {
    transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(360deg);
    opacity: 1;
  }
}
 
@keyframes rotate-1-out {
  0% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  35% {
    transform: rotateX(-40deg);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(180deg);
    opacity: 0;
  }
}
*/

header {
	background-color: fade(@color-green, 50%);	
    transition: background-color .4s ease-out;
    -moz-transition: background-color .4s ease-out;
    -webkit-transition: background-color .4s ease-out;
    -o-transition: background-color .4s ease-out;		
	text-align: center;
	#head {
		margin: 0 auto;
		max-width: 900px;		
		border-bottom: 2px solid fade(#000000, 10%);
		h1 {
			font-family: "soleil", arial, sans-serif;
			font-size: 64px;
			font-weight: 700;
			margin: 0;
			padding: 54px 100px;
			.breakpoint-small({
				font-size: 32px;
				padding: 20px 20px 10px 20px;
			});			
		}	
		ul#navigation {
			text-align: center;
			list-style: none;
			overflow: auto;
			margin: 0; padding: 0;
		}	
		ul#navigation li {			
			display: inline-block;	
			margin-right: 0px;
			padding-bottom: 15px;
			padding-top: 15px;
		}	
		ul#navigation a {
			color: fade(#000000, 50%);
			text-decoration: none;
			margin-right: 20px;
		}	
		ul#navigation a:hover, ul#navigation a.active {	
			color: fade(#000000, 100%);	
		}				
		ul#navigation li:last-child a  {
			margin-right: 0;
		}	
	}
	#head.project {
		h1 {
			padding-bottom: 154px;
			.breakpoint-small({
				padding: 40px 20px 140px 20px;
			});
		}
	}
}

/*
* Header color definitions
*/

#home header {
	background-color: fade(@color-green, 50%);
}

#skills header,
#about header {
	background-color: fade(@color-green, 50%);	
}
#work header {
	background-color: fade(@color-green, 50%);
}

#contact header {
	background-color: fade(@color-green, 50%);
}



section {
	.content {	
		margin: 0 auto;
		padding: 54px 10px 0px 10px;
		max-width: 580px;
		.breakpoint-small({
			padding: 40px 20px 0px 20px;
		});					
		.small {
			font-size: 14px;
			line-height: 23px;
			margin-bottom: 23px;
		}
		p.centered {
			text-align: center;
		}	
		p.c2a {
			font-weight: 700;
			margin-top: 54px;
			margin-bottom: 14px;
		}		
		h2:first-child {
			margin-top: 0px;
		}
	}
	.work {
		margin: 0 auto;
		max-width: 900px;	
		text-align: center;
		margin-top: 54px;
		ul {
			text-align: center;
			list-style: none;
			overflow: auto;
			margin: 0; padding: 0;
		}	
		ul li {			
			display: inline-block;
			width: 25%;	
			margin-right: 0px;
			padding-bottom: 27px;
			.breakpoint-small({
				width: 40%;
			});
		}
		ul li p.desc {	
			margin-top: 10px;
			text-align: center;
			font-size: 14px;
			line-height: 20px;	
		}
		ul li a {
			text-decoration: none;
			color: fade(#000000, 50%);
		}
		ul li a:hover {		
			color: fade(#000000, 100%);
		}
		ul li img {
			zoom: 50%;	
			-moz-transform: scale(0.5);
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);	
			opacity: 0.4;	
		    transition: all .2s ease-out;
		    -moz-transition: all .2s ease-out;
		    -webkit-transition: all .2s ease-out;
		    -o-transition: all .2s ease-out;				
		}
		ul li.overview img {
			zoom: 20%;	
			-moz-transform: scale(0.2);		
			margin: 0 20px 20px 20px;	
		}
		ul li:hover img {	
			-webkit-filter: grayscale(0%);
			filter: grayscale(0%);	
			opacity: 1;			
		}
	}
	.screenshots {
		margin: 0 auto;
		max-width: 900px;	
		text-align: left;
		margin-top: -100px;
		padding: 0px 20px 0px 20px;
		position: relative;
		padding-bottom: 13px;
		.breakpoint-small({
			padding-bottom: 0;
		});
		img {
			border: 2px solid fade(#000000, 10%);	
			width: 100%;		
			max-width: 600px;			
		}			
	}
	.screenshots.full-mobile {
		img.full {
			margin-left: 50px;
			.breakpoint-small({
				margin: 0 0;
			});
		}		
		img.mobile {
			max-width: 200px;
			position: absolute;
			top: 70px;
			right: 70px;
		}			
	}
	.screenshots.full-screen {				
		img.screen {
			max-width: 350px;
			position: absolute;
			top: 60px;
			right: 20px;			
		}			
	}			
}

section#projects {
	ul li {
		margin-bottom: 13px;
	}
}

footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;	
	background: transparent;
	text-align: center;
	#foot {
		margin: 0 auto;
		max-width: 580px;		
		border-top: 1px solid fade(#000000, 10%);
		ul.networks {
			text-align: center;
			list-style: none;
			overflow: auto;
			margin: 0; padding: 0;
		}
		ul.networks li {
			display: inline-block;	
			margin-right: 0px;
			padding: 15px 0px;
		}
		ul.networks li:last-child a  {
			margin-right: 0;
		}		
		ul.networks a {
			color: #888;
			text-decoration: none;
			margin-right: 20px;
		}	
		ul.networks a:hover {	
			color: #000;	
		}
		.network-name {
			display: none;
			.breakpoint-small({
				display: none;
			});				
		}
	}	
}


/*
* Default typography
*/

p, ul {
	line-height: 27px;	
	text-align: left;
	margin-bottom: 27px;
	.breakpoint-small({
		font-size: 16px;
		line-height: 23px;
	});
}

ul {
	padding-left: 23px;
}

p.larger {
	font-size: 22px;
	line-height: 32px;
	margin-bottom: 32px;
	.breakpoint-small({
		font-size: 18px;
		line-height: 27px;
	});			
}	

#intro {
	p {
		margin-bottom: 16px;
	}
}

.content:hover p,
.content:hover ul {
	color: #000;
}

//FOUT fix for Typekit
.wf-loading h1, .wf-loading h2, .wf-loading h3, .wf-loading p, .wf-loading ul {
	visibility: hidden;
}

.wf-active h1, .wf-active h2, .wf-active h3, .wf-active p, .wf-active ul, 
.wf-inactive h1, .wf-inactive h2, .wf-inactive h3, .wf-inactive p, .wf-inactive ul {
	visibility: visible;
}

h2 {
	display: inline-block;
	font-size: 22px;
	line-height: 32px;
	border: 5px solid #000;
	padding: 1px 10px 3px 10px;	
	margin: 27px 0px 27px 0px;
	background-color: #000;
	color: #fff;
	.breakpoint-small({
		font-size: 18px;
		line-height: 27px;
		margin: 23px 0px 23px 0px;		
	});			
}

h3 {
	display: inline-block;
	font-size: 16px;
	line-height: 25px;
	border: 5px solid #000;
	padding: 1px 10px 3px 10px;	
	margin: 25px 0px 25px 0px;
	background-color: #000;
	color: #fff;		
}




/*
* Links
*/

a, 
a:link,
a:visited {
	color: #333;
	text-decoration: underline;
    transition: color .2s ease-out;
    -moz-transition: color .2s ease-out;
    -webkit-transition: color .2s ease-out;
    -o-transition: color .2s ease-out;		
}

a:hover, 
a:active {
	color: #000;
}

h1 a, 
h1 a:link,
h1 a:active,
h1 a:visited {
	color: fade(#000000, 80%);
	text-decoration: none;
}

h1 a:hover {
	color: fade(#000000, 90%);
	text-decoration: none;	
}

a.button {
	font-size: 22px;
	line-height: 100%;
	display: inline-block;
	border: 2px solid fade(#000000, 50%);
	padding: 7px 15px 10px 15px;
	margin-bottom: 27px;
	color: fade(#000000, 50%);
	text-decoration: none;
	.border-radius();
}

a.button:hover,
.content:hover a.button {
	border-color: #000;
 	background-color: #fff;
	color: #000;	
}